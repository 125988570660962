import React, { Component } from 'react'
import { FaChevronDown } from 'react-icons/fa'

import Snapcard from '../images/Snapcard.png'
import SnapCard from '../images/SnapCard.jpg'
import Selectrooms from '../images/Slectrooms.png'
import DailyBrief from '../images/DailyBrief.png'
import DailyBriefimg from '../images/DailyBriefimg.png'
import DetailsSection from '../components/DetailsSection'
import Footer from '../components/Footer'
import Layout from '../layouts'
import Scroller from '../components/Scroll'
import { withScroll } from '../components/Scroll'
import techimg from '../images/TECH.png'
import HomeSection from '../components/HomeSection'
class Technology extends Component {
  constructor(props) {
    super(props)
    this.state = {
      whiteLogo: this.updateHeaderColor(props.activeSection),
      activeColor: this.getActiveColor(props.activeSection),
    }
  }
  componentDidMount() {
    switch (window.location.hash) {
      case '#dailybrief':
        this.props.goToSection(1)
        break
      case '#snapcard':
        this.props.goToSection(2)
        break
      default:
        this.props.goToSection(0)
        break
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { whiteLogo, activeColor: activeColorState } = this.state
    if (nextProps.activeSection !== this.props.activeSection) {
      const white = this.updateHeaderColor(nextProps.activeSection)
      if (white !== whiteLogo) {
        this.setHeaderColor(white)
      }
      const activeColor = this.getActiveColor(nextProps.activeSection)
      if (activeColor !== activeColorState) {
        this.setActiveColor(activeColor)
      }
    }
  }
  componentDidUpdate() {
    const { activeSection, scrolling } = this.props
    if (!scrolling) {
      switch (window.location.hash) {
        case '#dailybrief':
          if (activeSection !== 1) this.props.goToSection(1)
          break
        case '#snapcard':
          if (activeSection !== 2) this.props.goToSection(2)
          break
        default:
          if (activeSection !== 0) this.props.goToSection(0)
          break
      }
    }
  }
  setHeaderColor = whiteLogo => this.setState({ whiteLogo })
  updateHeaderColor = active => {
    switch (active) {
      case 0:
        return true
      default:
        return false
    }
  }
  getActiveColor = active => {
    switch (active) {
      case 1:
        return '#ec2027'
      case 2:
        return '#3e91be'
      default:
        return 'grey'
    }
  }
  setActiveColor = activeColor => this.setState({ activeColor })

  render() {
    const { whiteLogo } = this.state
    const { activeSection } = this.props
    return (
      <Layout whiteLogo={whiteLogo}>
        {activeSection !== 0 && (
          <Scroller.DotGroup
            className="dots"
            renderDot={({ active, index, ...props }) => (
              <button
                className="dot"
                {...props}
                style={{
                  backgroundColor:
                    index === active ? this.getActiveColor(active) : '#727272',
                }}
              />
            )}
          />
        )}
        {/* {activeSection !== 0 && (
          <Scroller.NextButton className="half-circle">
            <div
              className="bottom-arrow"
              style={{ backgroundColor: '#ec2027' }}
            >
              <i>
                <FaChevronDown color="#fff" />
              </i>
            </div>
          </Scroller.NextButton>
        )} */}
        <Scroller.Sections>
          <Scroller.Section>
            <HomeSection
              id="technology"
              Heading="TECHNOLOGY"
              links={[
                {
                  to: '/technology#dailybrief',
                  name: 'INCUBATING AMBITIOUS, WORLD-CLASS STARTUPS',
                },
              ]}
              links1={[
                { to: 'technology#dailybrief', name: 'DAILYBRIEF' },
                { to: 'technology#snapcard', name: 'SNAPCARD' },
              ]}
              img={techimg}
            />
          </Scroller.Section>
          <Scroller.Section className="mobile-default-scroll">
            <DetailsSection
              id="dailybrief"
              src={DailyBrief}
              imgurl={DailyBriefimg}
              buttonLink="https://news.getdailybrief.com"
              buttonText="Know More"
              stats={[
                { number: '250,000+', text: 'Downloads' },
                { number: '4.5', text: 'Rating' },
                { number: '30,000+', text: 'Stories' },
              ]}
              headerText="News, views and ideas that matter. DailyBrief gives users the top news stories of the day in a short, condensed format. Our writers create specialized news briefs that focus on the most important points of a story, so that you can get briefed with everything that’s going on, in 5 minutes."
            />
          </Scroller.Section>
          <Scroller.Section className="mobile-default-scroll pb-0">
            <DetailsSection
              id="snapcard"
              src={Snapcard}
              imgurl={SnapCard}
              buttonLink="https://gosnapcard.com/"
              buttonText="Know More"
              stats={[
                { number: '10,000+', text: 'Exhibiting Companies' },
                { number: '100+', text: 'Countries' },
                { number: '100,000+', text: 'Attendees' },
              ]}
              headerText="SnapCard lets you create, search, exchange and manage business cards, all from your smartphone. Just scan someone’s badge to exchange cards. No more carrying stacks of paper cards from event to event, only for them to run out just when you were making that crucial connection. No more having to sort through hundreds of cards by hand, scribbling notes on their back, and saving them on your smartphone."
            />
            <Footer />
          </Scroller.Section>
        </Scroller.Sections>
      </Layout>
    )
  }
}
export default withScroll({ sections: 3 })(Technology)
